import { css } from '@amedia/brick-tokens';

import { Payload } from '../../types/attributes';

const opinionWrapper = css({
  display: 'flex',
  alignItems: 'center',
  gap: '$x2',
  marginTop: '$x4',
  marginBottom: '$x6',
});

const authorImageStyle = css({
  height: '40px',
  width: '40px',
  objectFit: 'cover',
  borderRadius: '9999px',
});

const authorTextWrapper = css({
  display: 'flex',
  flexDirection: 'column',
});

const authorNameStyle = css({
  margin: '$none',
  fontstyle: 'baseSubheadS',
  fontStyle: 'normal',
  color: '$baseFg',
});

export const opinion = (data: typeof Payload) => {
  const { authorName, authorImage, authorRole, opinion } = data;

  if (!opinion || !authorName) return '';

  return /* html */ `
<div class="${opinionWrapper()}">
    ${
      authorImage
        ? `<img class="${authorImageStyle()}" src="${authorImage}" alt="Bilde av ${authorName}" loading="lazy">`
        : ''
    }
      <div class="${authorTextWrapper()}">
        <cite class="${authorNameStyle()}">${`Av: ${[authorName, authorRole]
          .filter(Boolean)
          .join(', ')}`}</cite>
    </div>
    
</div>
`;
};
