import { template } from './template';
import { Payload } from './types/attributes';

export class BrickAltTeaser extends HTMLElement {
  observer?: IntersectionObserver;
  payload = { ...Payload };

  get template() {
    let layout:
      | 'image-bottom'
      | 'image-top'
      | 'video-top'
      | 'opinion'
      | 'small' = 'image-bottom';
    switch (true) {
      case !!this.payload.opinion:
        layout = 'opinion';
        break;
      case !!this.payload.gallery:
      case !!this.payload.isVideo:
        layout = 'video-top';
        break;
      case this.payload.feature:
        layout = 'image-top';
        break;
      default:
        layout = 'image-bottom';
    }
    return template(this.payload, layout);
  }

  getStringAttribute(attribute: string) {
    return this.getAttribute(attribute) || '';
  }

  getBooleanAttribute(attribute: string) {
    return this.getAttribute(attribute) === 'true';
  }

  /**
   * Gets all attributes defined in Payload from the DOM element
   */
  getAttributes() {
    return Object.entries(Payload).reduce(
      (obj, [key, value]) => {
        if (typeof value === 'boolean')
          obj[key] = this.getBooleanAttribute(key);
        else obj[key] = this.getStringAttribute(key);
        return obj;
      },
      {} as typeof Payload
    );
  }

  render() {
    this.innerHTML = this.template;
  }

  connectedCallback() {
    this.payload = this.getAttributes();

    this.render();

    this.querySelector('[data-adp-clickvalue="open-menu"]')?.addEventListener(
      'click',
      this.menuOnClickEvent.bind(this)
    );

    this.querySelector('[data-adp-clickvalue="publication"]')?.addEventListener(
      'mouseover',
      this.publicationOnHoverEvent.bind(this)
    );
  }

  disconnectedCallback() {
    this.querySelector(
      '[data-adp-clickvalue="open-menu"]'
    )?.removeEventListener('click', this.menuOnClickEvent.bind(this));

    this.querySelector(
      '[data-adp-clickvalue="publication"]'
    )?.removeEventListener(
      'mouseover',
      this.publicationOnHoverEvent.bind(this)
    );
  }

  menuOnClickEvent() {
    const el = this.querySelector('[data-adp-clickvalue="open-menu"]');
    const isExpanded = el?.getAttribute('aria-expanded') === 'true';
    el?.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
    const event = new CustomEvent(this.payload.menuOnClickEventName, {
      bubbles: true,
    });
    this.dispatchEvent(event);
  }

  publicationOnHoverEvent() {
    const event = new CustomEvent(this.payload.publicationOnHoverEventName, {
      bubbles: true,
    });
    this.dispatchEvent(event);
  }
}

if (!customElements.get('brick-alt-teaser-v5')) {
  customElements.define('brick-alt-teaser-v5', BrickAltTeaser);
}
